import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { PublicGuard } from './guards/public.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome', // TODO: Set this to ''
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => import('./pages/secure/secure.module').then(m => m.SecureModule),
    // canActivate: [AuthGuard] // Secure all child pages
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/public/welcome/welcome.module').then(m => m.WelcomePageModule),
    // canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'signin',
    loadChildren: () => import('./pages/public/signin/signin.module').then(m => m.SigninPageModule),
    // canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/public/signup/signup.module').then(m => m.SignupPageModule),
    // canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./pages/public/password-reset/password-reset.module').then( m => m.PasswordResetPageModule),
    // canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'nova-transacao',
    loadChildren: () => import('./nova-transacao/nova-transacao.module').then( m => m.NovaTransacaoPageModule)
  },
  {
    path: 'alterar-senha',
    loadChildren: () => import('./alterar-senha/alterar-senha.module').then( m => m.AlterarSenhaPageModule)
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./usuarios/usuarios.module').then( m => m.UsuariosPageModule)
  },
  {
    path: 'saque',
    loadChildren: () => import('./saque/saque.module').then( m => m.SaquePageModule)
  },
  {
    path: 'detalha-usuario',
    loadChildren: () => import('./detalha-usuario/detalha-usuario.module').then( m => m.DetalhaUsuarioPageModule)
  },
  {
    path: 'detalha-usuario/:id',
    loadChildren: () => import('./detalha-usuario/detalha-usuario.module').then( m => m.DetalhaUsuarioPageModule)
  },
  {
    path: 'criar-usuario',
    loadChildren: () => import('./criar-usuario/criar-usuario.module').then( m => m.CriarUsuarioPageModule)
  },
  {
    path: 'cobrancas',
    loadChildren: () => import('./cobrancas/cobrancas.module').then( m => m.CobrancasPageModule)
  },
  {
    path: 'trocar-plano',
    loadChildren: () => import('./trocar-plano/trocar-plano.module').then( m => m.TrocarPlanoPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
